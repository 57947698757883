<template>
  <div class="codelogin_box">
    <div class="login-top">
        <van-icon name="cross" size="25"/>
    </div>
    <div class="title">
        <div class="text1">登录电商</div>
        <div class="text2">未注册手机验证后自动登录</div>
    </div>
    <!-- 登入框 -->
    <div class="login_pop">
        <div class="account_div">
            <input class="account" type="number" placeholder="请输入手机号">
        </div>
    </div>
    <div class="last_but">
        <div class="login_but" @click="gocode">获取验证码</div>
        <div class="dx_but" @click="gomimalogin">密码登录</div>
        <van-checkbox v-model="checked" class="checkbox" icon-size="14">
            <tt>我已阅读并同意</tt><span>《用户协议》《隐私保护指引》</span>
        </van-checkbox>
    </div>
    <bottomList></bottomList>
  </div>
</template>

<script>
export default {
  name: "codeLogin",

  data() {
    return {
        checked: false
    };
  },

  mounted() {},

  methods: {
    gomimalogin() {
        this.$router.push('./index')
    },
    gocode() {
        this.$router.push('./codeIndex')
    }
  },
};
</script>

<style lang="less" scoped>
.codelogin_box{
    width: 100vw;
    height: 100vh;
    background: #fff;
}
.login-top{
    display: flex;
    justify-content: space-between;
    padding: 15rem 17rem;
}
.title{
    padding: 30rem 32rem;
    .text1{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 24rem;
        color: #000000;
    }
    .text2{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14rem;
        color: #BEC2D1;
        margin-top: 2rem;
    }
}
.login_pop{
    padding: 30rem 32rem;
    input{
        border: none;
        font-family: DINPro, DINPro;
        font-weight: 500;
        font-size: 18rem;
        color: #333333;
    }
    .account{
        padding: 10rem 0rem;
    }
    .password{
        padding: 10rem 0rem;
    }
}
.account_div{
    border-bottom: 1rem solid #F5F6FB;
}
.password_div{
    border-bottom: 1rem solid #F5F6FB;
}
.last_but{
    margin-top: 40rem;
    padding: 0rem 33rem;
    .login_but{
        height: 49rem;
        background: #E0BFB1;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
    }
    .dx_but{
        height: 49rem;
        background: #F2F3FC;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 15rem;
        color: #333333;
        text-align: center;
        line-height: 49rem;
        margin-top: 12rem;
    }
    .checkbox{
        margin-top: 12rem;
        tt{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #8D9399;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 11rem;
            color: #CBAA9C;
        }
    }
}
</style>
